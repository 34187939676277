import Cookies from 'cookies';
import { GetServerSidePropsContext } from 'next';
import { Cache } from '@/data/types/Cache';
import { PARAMETER_COOKIES } from '@/data/constants/cookies';
import { getSettings, useSettings } from '@/data/Settings';
import { customMarketing } from 'integration/generated/custom';
import useSWR, { unstable_serialize } from 'swr';
import { useUserContext } from '@/data/UserContext';
import { getServerSideCommon } from '@/data/utils/getServerSideCommon';
import { DATA_KEY_PROMOCODE } from '@/data/constants/dataKey';
import { useNextRouter } from '@/data/Content/_NextRouter';

export const DATA_KEY = DATA_KEY_PROMOCODE;

type GetCookieProps = {
	context: GetServerSidePropsContext;
	cookieConfig: { dataKey: string; maxAge: number; paramName: string };
	cache: Cache;
};

type GetContextProps = {
	context: GetServerSidePropsContext;
	cache: Cache;
};

type GetUpdateCacheProps = {
	context: GetServerSidePropsContext;
	cache: Cache;
	value: string | undefined;
	dataKey: string;
	hasKey: boolean;
};

export const updateCache = async ({
	context,
	cache,
	value,
	dataKey,
	hasKey,
}: GetUpdateCacheProps) => {
	if (!value && hasKey) {
		const cookie = new Cookies(context.req, context.res);
		cache.set(dataKey, cookie.get(dataKey));
	} else if (value) {
		cache.set(dataKey, value);
	}
};

const customEventFetcher =
	(pub: boolean) => async (DM_ReqCmd: string, PromoCode: string, storeId: string) => {
		try {
			return await customMarketing(pub).eventTriggerMarketing(storeId ?? '', undefined, {
				DM_ReqCmd,
				PromoCode,
			});
		} catch (e) {
			return undefined;
		}
	};

export const setGenericCookie = ({ cookieConfig, context, cache }: GetCookieProps) => {
	const { dataKey, maxAge, paramName } = cookieConfig;
	const paramValue = context.query[paramName];
	const cookieKeys = Object.keys(context.req.cookies);
	const hasKey = cookieKeys.includes(dataKey);
	const cookie = new Cookies(context.req, context.res);
	const options = { maxAge, overwrite: true, httpOnly: true };
	let valueForCache = undefined;
	if (paramValue) {
		cookie.set(dataKey, paramValue as string, options);
		valueForCache = paramValue;
	}
	updateCache({
		context,
		cache,
		value: valueForCache as string | undefined,
		dataKey: cookieConfig.dataKey,
		hasKey,
	});
};

export const setPromoCodeCookie = async ({ cookieConfig, context, cache }: GetCookieProps) => {
	const { dataKey, maxAge, paramName } = cookieConfig;
	const paramValue = context.query[paramName];
	const cookieKeys = Object.keys(context.req.cookies);
	const hasKey = cookieKeys.includes(dataKey);
	const cookie = new Cookies(context.req, context.res);
	const options = { maxAge, overwrite: true, httpOnly: false };
	let cookiePromoCodeValue = undefined;
	if (paramValue) {
		if (!hasKey) {
			cookiePromoCodeValue = paramValue;
		} else {
			const splits = cookie.get(dataKey)?.split('|');
			if (!splits?.includes(paramValue as string)) {
				cookiePromoCodeValue = `${cookie.get(dataKey)}|${paramValue}`;
			}
		}
		if (cookiePromoCodeValue) {
			cookie.set(dataKey, cookiePromoCodeValue as string, options);
		}
		updateCache({
			context,
			cache,
			value: paramValue as string | undefined,
			dataKey: cookieConfig.dataKey,
			hasKey,
		});
	}
};

export const getUrlParamCookies = async ({ context, cache }: GetContextProps) => {
	setPromoCodeCookie({ cookieConfig: PARAMETER_COOKIES[0], context, cache });
	setGenericCookie({ cookieConfig: PARAMETER_COOKIES[1], context, cache });
	setGenericCookie({ cookieConfig: PARAMETER_COOKIES[2], context, cache });
};

export const getUrlPromoCode = async (cache: Cache, context: GetServerSidePropsContext) => {
	const settings = await getSettings(cache, context);
	const { storeId } = getServerSideCommon(settings, context);
	const promoCodeValue = context.query[PARAMETER_COOKIES[0].dataKey] as string;
	const key = unstable_serialize([{ storeId, promoCodeValue }, DATA_KEY]);

	if (typeof promoCodeValue === 'string') {
		await customEventFetcher(false)('PromoCodeMarketingEvent', promoCodeValue, storeId);
		cache.set(key, promoCodeValue);
	}
};

export const useUrlPromoCode = async () => {
	const { settings } = useSettings();
	const { data: promoCode } = useSWR(PARAMETER_COOKIES[0].dataKey);
	const {user} = useUserContext();
	const router = useNextRouter();
	const { query } = router;
	const { PromoCode } = query; // getting promoCode from client side router
	const promoCodeValue = promoCode ?? PromoCode;
	 const {data} = useSWR(
		promoCodeValue && user
			? [
					{
						storeId: settings?.storeId,
						promoCodeValue,
					},
			  ]
			: null,

		async ([{storeId, promoCodeValue}]) =>
			customEventFetcher(true)('PromoCodeMarketingEvent', promoCodeValue, storeId)
	);
};
