/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited 2023, 2024.
 */

export const DATA_KEY_PAYMENT_INFO = 'PAYMENT_INFO';
export const DATA_KEY_SHIPPING_INFO = 'USABLE_SHIPPING_INFO';
export const DATA_KEY_PERSON = 'person';
export const DATA_KEY_ORDER_BY_ID = 'order-by-id';
export const DATA_KEY_PRODUCT_BY_ID = 'product-by-id';
export const DATA_KEY_ARTICLE_BY_ID = 'article-by-id';
export const DATA_KEY_NEWS_WIDGET_LIST_BY_ID = 'article-by-id';
export const DATA_KEY_EVENTS_WIDGET_LIST_BY_ID = 'events-by-id';
export const DATA_KEY_EVENTS_WIDGET_LIST_BY_TAG = 'events-by-tag';
export const DATA_KEY_ORDERS_BY_STATUS = 'orders-by-status';
export const DATA_KEY_WISH_LIST = 'wish-list';
export const DATA_KEY_CHECKOUT_PROFILES = 'CHECKOUT_PROFILES';
export const DATA_KEY_CONTRACT = 'contract';
export const DATA_KEY_ORGANIZATION_DESCRIPTIONS = 'organization-descriptions';
export const DATA_KEY_USER = 'User';
export const DATA_KEY_JJKUSER = 'JJKUser';
export const DATA_KEY_CART = 'cart';
export const DATA_KEY_REQUISITION_LIST = 'RequisitionList';
export const DATA_KEY_ORGANIZATION_DETAILS = 'organization-details';
export const DATA_KEY_VIEW_UPLOAD_LOG = 'ViewUploadLog';
export const DATA_KEY_SMARTY_KEY = 'SMARTY_EMBEDDED_KEY';
export const DATA_KEY_OKTA_ISSUER_ID = 'OKTA_ISSUER_ID';
export const DATA_KEY_OKTA_CLIENT_ID = 'OKTA_CLIENT_ID';
export const DATA_KEY_APP_DYNAMICS_KEY = 'APP_KEY_ID';
export const COOKIES_KEY = 'COOKIE_KEY_ID';
export const DATA_KEY_EVENT_URL = 'EVENT_URL';
export const DATA_KEY_PREVIOUS_URL = 'PREVIOUS_URL';
export const DATA_KEY_CUSTOM_SIGNS_HOSTNAME = 'CUSTOM_SIGNS_HOSTNAME';
export const DATA_KEY_IMPRINT_SEAL_ATRRIBUTES_BY_PARTNUMBER =
	'imprint-seal-attributes-by-partnumber';
export const DATA_KEY_EDITORAIL_TOPIC_LIST = 'EditorialTopicList';
export const DATA_KEY_EDITORAIL_TOPIC_SEARCH = 'EditorialTopicSearch';
export const DATA_KEY_EDITORAIL_TOPIC_ARTICLE = 'EditorialTopicArticle';
export const DATA_KEY_SIMPLE_NEWS_WIDGET = 'simple-news-list';
export const DATA_KEY_PRODUCT = 'PRODUCT';
export const DATA_KEY_PRODUCT_PRICE = 'DATA_KEY_PRODUCT_PRICE';
export const DATA_KEY_SETTINGS = 'SETTINGS';
export const DATA_KEY_PAGE_DATA_FROM_ID = 'PAGE_DATA_FROM_ID';
export const DATA_KEY_E_SPOT_DATA_FROM_NAME = 'E_SPOT_DATA_FROM_NAME';
export const DATA_KEY_E_SPOT_DATA_FROM_NAME_DYNAMIC = 'E_SPOT_DATA_FROM_NAME_DYNAMIC';
export const DATA_KEY_BREADCRUMB = 'BREADCRUMB';
export const DATA_KEY_FLOW_DATA_FROM_ID = 'FLOW_DATA_FROM_ID';
export const DATA_KEY_PRODUCT_MULTI = 'PRODUCT_MULTI';
export const DATA_KEY_PRODUCTS = 'PRODUCTS';
export const DATA_KEY_FLEX_FLOW_STORE_FEATURE = 'STORE_FEATURE';
export const DATA_KEY_PAGES_BY_TYPE = 'PAGES_BY_TYPE';
export const DATA_KEY_QUICK_ORDER_SKU_DETAILS = 'DATA_KEY_QUICK_ORDER_SKU_DETAILS';
export const DATA_KEY_PAGES_URL = 'DATA_KEY_PAGES_URL';
export const DATA_KEY_EMAIL_TEMPLATE_LOOKUP = 'EMAIL_TEMPLATE';
export const DATA_KEY_COMMERCE_AI = 'COMMERCE_AI';
export const DATA_KEY_INVENTORY = 'INVENTORY';
export const DATA_KEY_INVENTORY_STATUS_BY_ORDER = 'INVENTORY_STATUS_BY_ORDER';
export const DATA_KEY_INVENTORY_STATUS_BY_ORDER_ITEM = 'INVENTORY_STATUS_BY_ORDER_ITEM';
export const DATA_KEY_STORE_LOCATOR_STORES = 'STORE_LOCATOR_STORES';
export const DATA_KEY_SAVE_FOR_LATER_LIST = 'SAVE_FOR_LATER_LIST';
export const DATA_KEY_SAVE_FOR_LATER_LIST_ITEMS = 'SAVE_FOR_LATER_LIST_ITEMS';
export const DATA_KEY_SUBSCRIPTION = 'SUBSCRIPTION';
export const DATA_KEY_BUYER_APPROVALS_MANAGEMENT = 'BUYER_APPROVALS_MANAGEMENT';
export const DATA_KEY_ORDER_APPROVALS_MANAGEMENT = 'ORDER_APPROVALS_MANAGEMENT';
export const DATA_KEY_BUYER = 'BUYER';
export const DATA_KEY_BUYER_DETAILS = 'BUYER_DETAILS';
export const DATA_KEY_BUYER_ROLE_DESCS = 'BUYER_ROLE_DESCRIPTIONS';
export const DATA_KEY_ORDER_APPROVAL_BY_ID = 'ORDER_APPROVAL_BY_ID';
export const DATA_KEY_BUYER_APPROVAL_DETAILS = 'BUYER_APPROVAL_DETAILS';
export const DATA_KEY_ORGANIZATION_MANAGEMENT = 'ORGANIZATION_MANAGEMENT';
export const DATA_KEY_MEMBER_GROUP = 'MEMBER_GROUP';
export const DATA_KEY_ORGANIZATION_ASSIGNABLE_ROLES = 'ORGANIZATION_ASSIGNABLE_ROLES';
export const DATA_KEY_NAVIGATION = 'NAVIGATION';
export const DATA_KEY_LOCALIZATION = 'LOCALIZATION';
export const DATA_KEY_PROMOCODE = 'PROMOCODE';
