/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Cart } from '@/components/blocks/Cart';
import { useCart } from '@/data/Content/Cart';
import { useProduct } from '@/data/Content/Product';
import { Button, Link } from '@mui/material';
import NextLink from 'next/link';

import { FC, memo, useState, ComponentProps } from 'react';

export const CartLink: FC<Omit<ComponentProps<typeof NextLink>, 'href'> & { partNumber: string, quantity: string, href?: string, sx?: any, elementChildProps?: any }> = memo(({ partNumber, quantity, children, sx, elementChildProps }) => {
	const { addToCart } = useCart();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const { product } = useProduct({ id: partNumber as string });
	const addtoCartHandler = () => {
		const onAddToCart =
			() => {
				setDrawerOpen(true);
			};
		addToCart(parseInt(partNumber), quantity, onAddToCart);
	};

	return (
		<>
			{elementChildProps && elementChildProps?.element === 'Button' ? (
				<Button onClick={addtoCartHandler} tabIndex={0} variant={elementChildProps?.variant} sx={sx}>{children}</Button>
			): (
				<Link component="button" onClick={addtoCartHandler} tabIndex={0} sx={sx}>
					{children}
				</Link>
			)}
			<Cart drawerOpen={drawerOpen} {...product} setDrawerOpen={setDrawerOpen} quantity={parseInt(quantity as string)} />
		</>
	);
});
