import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Drawer,
	Stack,
	Typography,
} from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { advantageDrawerSX, updateHeadingSX } from '@/components/content/AdvForm/styles/style';
import { useCart } from '@/data/Content/Cart';
import { useProduct } from '@/data/Content/Product';
import { Cart } from '@/components/blocks/Cart';
import { useContentRecommendation } from '@/data/Content/ContentRecommendation';
import { useLocalization } from '@/data/Localization';
import { renderContent } from '@/utils/renderContent';
import { Linkable } from '@/components/blocks/Linkable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/sharp-solid-svg-icons';
import { deleteCookie } from 'cookies-next';
import { ADVANTAGE_MEMBER_PRODUCT } from '@/data/constants/marketing';

type Props = {
	jjkDrawerOpen: boolean;
	setJJKDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
	advMembershipPartNumEspot: string;
    advMembershipContentEspotName: string;
	subscribedFromCheckout?: boolean | undefined;
	setAddedToCart?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AdvantageMembershipFlyout: FC<Props> = ({
	jjkDrawerOpen,
	setJJKDrawerOpen,
	advMembershipPartNumEspot,
	advMembershipContentEspotName,
	subscribedFromCheckout = false,
	setAddedToCart,
}) => {
	const localization = useLocalization('AdvantagePDP');
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [isChecked, setIsChecked] = useState(false);
	const quantity = '1';
	const { addToCart } = useCart();
	const { data: espotData} = useContentRecommendation(advMembershipPartNumEspot);
	const { data: espotContent, loading: espotContentLoading} = useContentRecommendation(advMembershipContentEspotName);
	const partNumber = espotData && Array.isArray(espotData) ? espotData[0]?.text : null;
	const { product } = useProduct({ id: partNumber as string });
	const isAdvFullMembership = advMembershipContentEspotName === ADVANTAGE_MEMBER_PRODUCT;

	const onCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(event?.target?.checked);
	}, []);

	const addtoCartHandler = useCallback(() => {
        const onAddToCart = () => {
			setJJKDrawerOpen(false);
			!subscribedFromCheckout ? setDrawerOpen(true): null;
			setAddedToCart ? setAddedToCart(true): null;
		};
		addToCart(parseInt(partNumber as string), quantity as string, onAddToCart);
		// To delete cookie only if the full adv membership product added in cart or the membership modal is closed.(More Details in 3707)
        isAdvFullMembership ? deleteCookie('addAdvNow'): null;
	}, [addToCart, partNumber, subscribedFromCheckout, isAdvFullMembership, setJJKDrawerOpen, setAddedToCart]);

	useEffect(() => {
		!jjkDrawerOpen ? setIsChecked(false) : null;
	}, [jjkDrawerOpen]);

	return (
		<>
			<Drawer anchor={'right'} open={jjkDrawerOpen} sx={advantageDrawerSX}>
				<Box>
					<Box component="div" sx={updateHeadingSX}>
						<Typography variant="h3">{localization.JJKAdvantage.t()}</Typography>
						<Typography
							variant="strong"
							component="span"
							onClick={() => {
								setJJKDrawerOpen(false);
							}}
						>
							<CloseIcon fontSize="small" />
						</Typography>
					</Box>
					<Box>
						{espotContentLoading ? (
							<CircularProgress sx={{color: 'radio.burgundy', display: 'block', margin: '0 auto'}} />
						): (
							<>
								{espotContent && Array.isArray(espotContent) ? (
									<Stack component="div">
										<Typography variant="body3Bold" sx={{ fontWeight: '400', color: '#4A5055' }}>
											{espotContent?.map((content) => renderContent(content))}
										</Typography>
									</Stack>
							) : null}
							</>
						)}
						{/* If drawer opening on checkout shipping page do not show terms of use links */}
						{!subscribedFromCheckout ? (
							<Stack component="div" direction="row" alignItems="center">
								<Checkbox
									sx={{
										color: 'icons.fillcolorHover',
										padding: '0',
										margin: '0 3px 0 0',
										'&.Mui-checked': {
											color: 'radio.burgundy',
										},
									}}
									onChange={(e) => onCheckboxChange(e)}
								/>
								<Typography variant='caption'>
									{localization.JJKTC.t()}
									<Linkable
										sx={{fontWeight: '600', padding: '0', marginLeft: '1px'}}
										type="link"
										variant="blue-link"
										href="https://www.jjkeller.com/learn/terms-conditions"
										target="_blank"
									>
										{localization.TermsOfUse.t()}
									</Linkable>
								</Typography>
							</Stack>
						): null}
					</Box>
					{/* If drawer opening on checkout shipping page do not show the buttons */}
					{!subscribedFromCheckout ? (
						<Stack component="div" id="btn-wrapper">
							<Button
								type="submit"
								variant="contained"
								size="large"
								data-testid="button-email-submit"
								id="button-email-submit"
								disabled={!isChecked}
								sx={{ margin: '24px 0 16px 0', lineHeight: '27px'}}
								onClick={addtoCartHandler}
							>
								<FontAwesomeIcon fontSize='16px' icon={faCartShopping} />
								{localization.AddToCart.t()}
							</Button>
							<Button
								type="submit"
								variant="blue-outlined"
								size="large"
								data-testid="button-email-cancel"
								id="button-email-cancel"
								onClick={() => {
									isAdvFullMembership ? deleteCookie('addAdvNow'): null;
									setJJKDrawerOpen(false);
								}}
								sx={{backgroundColor: 'transparent', lineHeight: '27px'}}
							>
								{localization.NoThanks.t()}
							</Button>
						</Stack>
					): (
						<Stack component="div" id="btn-wrapper">
							<Button
								type="submit"
								variant="blue-outlined"
								size="large"
								data-testid="button-email-cancel"
								id="button-email-cancel"
								onClick={() => {
									isAdvFullMembership ? deleteCookie('addAdvNow'): null;
									setJJKDrawerOpen(false);
								}}
								sx={{backgroundColor: 'transparent', lineHeight: '27px'}}
							>
								{localization.Close.t()}
							</Button>
						</Stack>
					)}
				</Box>
			</Drawer>
			{!subscribedFromCheckout ? (
				<Cart
					drawerOpen={drawerOpen}
					{...product}
					setDrawerOpen={setDrawerOpen}
					quantity={Number(quantity)}
				/>
			): null}
		</>
	);
};
